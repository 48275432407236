
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  margin: 0;
  padding: 0;
}
html {
  min-height: 100%;
}
body {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  background: none;
  color: #444;
  line-height: 22px;
  background-color: #ffffff;
  font-weight: 400;
}





h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

a,
a:hover {
  text-decoration: none;
  color: #20a5e8;
  transition: all 150ms ease-in-out;
}

li {
  list-style: none outside none;
}

ul {
  margin: 0;
}

img {
  border: 0 none;
  max-width: 100%;
}

.clear {
  clear: both;
}

/*SCROLL*/

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}

body,
html {
  scrollbar-color: #aaa #f1f1f1;
  scrollbar-width: thin;
}
@media (min-width: 1200px)
{
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 960px;
}
}
.shadow-sm {
  -webkit-box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
          box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}
.shadow {
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
          box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}
.shadow-md {
  -webkit-box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
          box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.shadow-lg {
  -webkit-box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
          box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.shadow-xl {
  -webkit-box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
          box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}
.shadow-2xl {
  -webkit-box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
          box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}
.shadow-inner {
  -webkit-box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
          box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
}
.shadow-none {
  -webkit-box-shadow: 0 0 #0000;
          box-shadow: 0 0 #0000;
}


.fade-enter {
  opacity: 0;
  transform: translateX(100%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: 350ms ease;
}

.fade-exit {
  opacity: 0;
  transform: translateX(-100%);
  transition: 350ms ease;
}
.img__default{
  height: 270px;
  object-fit: cover;
}
.font__footer{
  font-size: 90%;
}
.div__img--effect{
  overflow: hidden;
  position:relative
}
.div__img--effect img{
  transition: 0.5s all;
}
.div__img--effect:hover img{
  transform: scale(1.1);
  background-color: #333;
}
.div__img--effect::after{
transition: 0.9s all;
content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.div__img--effect:hover::after{
  
  
  background: #6c757d;
  opacity: 0.3;
  z-index: 4;
  top: 0;
  left: 0;
}
.min--500{
  min-height: 700px;
}
.cursor--pointer{
  cursor: pointer;
}
.object-fit-contain{
  object-fit: contain;
}
.font--07{
  font-size: 0.7rem;
}