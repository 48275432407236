.zalo__icon{
  height: 1rem;
  width:1rem;
}
.menu__mobile{
  display: flex !important;
  flex-wrap: wrap;
  width: 100%;
}
.menu--url__mobile{
  flex-direction: column;
}